import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const Link = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 16 16">
      <Path fill="none" d="M0 0h16v16H0z" />
      <Path
        fill={color}
        d="M12.2427 10.3573L11.3 9.41333L12.2427 8.47066C12.5544 8.16162 12.8021 7.79406 12.9714 7.38906C13.1407 6.98406 13.2283 6.5496 13.2293 6.11064C13.2303 5.67167 13.1445 5.23683 12.977 4.8311C12.8094 4.42536 12.5634 4.05671 12.253 3.74631C11.9426 3.43592 11.574 3.18988 11.1682 3.02234C10.7625 2.8548 10.3276 2.76905 9.88868 2.77001C9.44971 2.77097 9.01526 2.85863 8.61026 3.02794C8.20526 3.19726 7.83769 3.44491 7.52865 3.75666L6.58599 4.69999L5.64265 3.75733L6.58665 2.81466C7.46187 1.93944 8.64891 1.44775 9.88665 1.44775C11.1244 1.44775 12.3114 1.93944 13.1867 2.81466C14.0619 3.68987 14.5536 4.87692 14.5536 6.11466C14.5536 7.3524 14.0619 8.53944 13.1867 9.41466L12.2433 10.3573H12.2427ZM10.3573 12.2427L9.41399 13.1853C8.53877 14.0605 7.35173 14.5522 6.11399 14.5522C4.87625 14.5522 3.6892 14.0605 2.81399 13.1853C1.93877 12.3101 1.44708 11.1231 1.44708 9.88533C1.44708 8.64759 1.93877 7.46054 2.81399 6.58533L3.75732 5.64266L4.69999 6.58666L3.75732 7.52933C3.44557 7.83836 3.19792 8.20593 3.02861 8.61093C2.85929 9.01593 2.77163 9.45038 2.77067 9.88935C2.76971 10.3283 2.85546 10.7632 3.023 11.1689C3.19055 11.5746 3.43658 11.9433 3.74698 12.2537C4.05737 12.5641 4.42602 12.8101 4.83176 12.9776C5.2375 13.1452 5.67233 13.2309 6.1113 13.23C6.55026 13.229 6.98472 13.1414 7.38972 12.972C7.79472 12.8027 8.16229 12.5551 8.47132 12.2433L9.41399 11.3007L10.3573 12.2433V12.2427ZM9.88532 5.17133L10.8287 6.11466L6.11465 10.828L5.17132 9.88533L9.88532 5.17199V5.17133Z"
      />
    </Svg>
  );
};
