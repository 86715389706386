import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const EarthFill = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 12 12">
      <Path fill="none" d="M0 0h12v12H0z" />
      <Path
        fill={color}
        d="M6 11C3.2385 11 1 8.7615 1 6C1 3.2385 3.2385 1 6 1C8.7615 1 11 3.2385 11 6C11 8.7615 8.7615 11 6 11ZM9.1775 7.976V7.9235C9.1775 7.4625 9.1775 7.252 8.8515 7.0655C8.74672 7.0061 8.63909 6.95187 8.529 6.903C8.3455 6.8195 8.224 6.765 8.06 6.525C8.04042 6.4965 8.02109 6.46783 8.002 6.439C7.8295 6.1765 7.705 5.9875 7.231 6.0625C6.2985 6.2105 6.2295 6.3745 6.1885 6.6515L6.182 6.697C6.1215 7.102 6.1105 7.238 6.2795 7.4155C6.912 8.079 7.291 8.5575 7.406 8.8375C7.462 8.974 7.606 9.3875 7.507 9.7965C8.11391 9.55476 8.65413 9.17124 9.0825 8.678C9.1375 8.491 9.1775 8.258 9.1775 7.976ZM6 1.9165C4.8415 1.9165 3.795 2.3995 3.052 3.1745C3.1405 3.236 3.2175 3.3225 3.2705 3.4415C3.3725 3.67 3.3725 3.9055 3.3725 4.114C3.3725 4.278 3.3725 4.434 3.425 4.5465C3.497 4.7005 3.808 4.7665 4.0825 4.8235C4.181 4.8445 4.282 4.8655 4.374 4.891C4.627 4.961 4.823 5.1885 4.9795 5.371C5.0445 5.4465 5.141 5.558 5.1895 5.586C5.2145 5.568 5.295 5.4805 5.3345 5.337C5.3655 5.227 5.3565 5.13 5.312 5.077C5.032 4.747 5.0475 4.112 5.134 3.8775C5.27 3.508 5.695 3.5355 6.006 3.5555C6.122 3.563 6.231 3.5705 6.313 3.56C6.624 3.521 6.72 3.0475 6.7875 2.955C6.9335 2.755 7.3805 2.4535 7.6575 2.2675C7.13575 2.0355 6.57101 1.9159 6 1.9165Z"
      />
    </Svg>
  );
};
