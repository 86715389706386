import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const MovieLine = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 16 16">
      <Path fill="none" d="M0 0h16v16H0z" />
      <Path
        fill={color}
        d="M1.33334 2.662C1.33456 2.48692 1.40461 2.31934 1.52835 2.19548C1.65209 2.07161 1.8196 2.0014 1.99468 2H14.0053C14.3707 2 14.6667 2.29667 14.6667 2.662V13.338C14.6655 13.5131 14.5954 13.6807 14.4717 13.8045C14.3479 13.9284 14.1804 13.9986 14.0053 14H1.99468C1.81922 13.9998 1.65101 13.93 1.527 13.8059C1.403 13.6817 1.33334 13.5135 1.33334 13.338V2.662ZM2.66668 3.33333V12.6667H13.3333V3.33333H2.66668ZM7.08134 5.61L10.334 7.778C10.3706 7.80234 10.4006 7.83535 10.4213 7.87408C10.4421 7.91281 10.4529 7.95606 10.4529 8C10.4529 8.04394 10.4421 8.08719 10.4213 8.12592C10.4006 8.16465 10.3706 8.19766 10.334 8.222L7.08068 10.39C7.04056 10.4166 6.994 10.4318 6.94592 10.4341C6.89785 10.4364 6.85006 10.4256 6.80762 10.4029C6.76517 10.3802 6.72966 10.3465 6.70485 10.3052C6.68004 10.264 6.66685 10.2168 6.66668 10.1687V5.83133C6.66677 5.7831 6.67994 5.73579 6.70478 5.69445C6.72963 5.65311 6.76522 5.61929 6.80777 5.59657C6.85033 5.57386 6.89824 5.56312 6.94641 5.56548C6.99459 5.56784 7.04122 5.58323 7.08134 5.61Z"
      />
    </Svg>
  );
};
