import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const LockUnlockLineClose = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 24 24">
      <Path fill="none" d="M0 0h24v24H0z" />
      <Path
        fill={color}
        d="M6 7V8H4C3.73478 8 3.48043 8.10536 3.29289 8.29289C3.10536 8.48043 3 8.73478 3 9V21C3 21.2652 3.10536 21.5196 3.29289 21.7071C3.48043 21.8946 3.73478 22 4 22H15V20H5V10H19V15H21V9C21 8.73478 20.8946 8.48043 20.7071 8.29289C20.5196 8.10536 20.2652 8 20 8H18H16H9.47372H8V7C8 5.93913 8.42143 4.92172 9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C14.9212 4.26434 15.0089 4.3612 15.0915 4.46173L16.7927 3.39024C16.6253 3.16798 16.4417 2.9564 16.2426 2.75736C15.1174 1.63214 13.5913 1 12 1C10.4087 1 8.88258 1.63214 7.75736 2.75736C6.63214 3.88258 6 5.4087 6 7ZM10.1522 14.7653C10.3207 15.1721 10.6187 15.5119 11 15.732V18H13V15.732C13.3813 15.5119 13.6793 15.1721 13.8478 14.7653C14.0162 14.3586 14.0458 13.9076 13.9319 13.4823C13.8179 13.057 13.5668 12.6813 13.2175 12.4132C12.8682 12.1452 12.4403 11.9999 12 11.9999C11.5597 11.9999 11.1318 12.1452 10.7825 12.4132C10.4332 12.6813 10.1821 13.057 10.0681 13.4823C9.9542 13.9076 9.98376 14.3586 10.1522 14.7653ZM23.536 22.121L21.414 20L23.536 17.879L22.121 16.464L20 18.586L17.879 16.464L16.464 17.879L18.586 20L16.464 22.121L17.879 23.536L20 21.414L22.121 23.536L23.536 22.121Z"
      />
    </Svg>
  );
};
