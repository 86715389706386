import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const Bold = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 16 16">
      <Path fill="none" d="M0 0h16v16H0z" />
      <Path
        fill={color}
        d="M5.33333 7.33335H8.33333C8.77536 7.33335 9.19928 7.15776 9.51184 6.8452C9.82441 6.53264 10 6.10871 10 5.66669C10 5.22466 9.82441 4.80074 9.51184 4.48818C9.19928 4.17562 8.77536 4.00002 8.33333 4.00002H5.33333V7.33335ZM12 10.3334C12 10.7273 11.9224 11.1174 11.7716 11.4814C11.6209 11.8454 11.3999 12.1761 11.1213 12.4547C10.8427 12.7332 10.512 12.9542 10.1481 13.105C9.78407 13.2558 9.39397 13.3334 9 13.3334H4V2.66669H8.33333C8.92064 2.66671 9.49502 2.83912 9.98525 3.16254C10.4755 3.48596 10.86 3.94615 11.0911 4.48607C11.3223 5.02598 11.3898 5.62186 11.2855 6.19982C11.1811 6.77777 10.9094 7.31238 10.504 7.73735C10.9591 8.00089 11.3369 8.37947 11.5995 8.83512C11.862 9.29077 12.0002 9.80746 12 10.3334ZM5.33333 8.66669V12H9C9.44203 12 9.86595 11.8244 10.1785 11.5119C10.4911 11.1993 10.6667 10.7754 10.6667 10.3334C10.6667 9.89133 10.4911 9.4674 10.1785 9.15484C9.86595 8.84228 9.44203 8.66669 9 8.66669H5.33333Z"
      />
    </Svg>
  );
};
