import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const ImageLine = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 16 16">
      <Path fill="none" d="M0 0h16v16H0z" />
      <Path
        fill={color}
        d="M3.21868 14L3.20534 14.0133L3.19134 14H1.99468C1.81922 13.9998 1.65101 13.93 1.527 13.8059C1.403 13.6817 1.33334 13.5135 1.33334 13.338V2.662C1.33456 2.48692 1.40461 2.31934 1.52835 2.19548C1.65209 2.07161 1.8196 2.0014 1.99468 2H14.0053C14.3707 2 14.6667 2.29667 14.6667 2.662V13.338C14.6655 13.5131 14.5954 13.6807 14.4717 13.8045C14.3479 13.9284 14.1804 13.9986 14.0053 14H3.21868ZM13.3333 10V3.33333H2.66668V12.6667L9.33334 6L13.3333 10ZM13.3333 11.8853L9.33334 7.88533L4.55201 12.6667H13.3333V11.8853ZM5.33334 7.33333C4.97972 7.33333 4.64058 7.19286 4.39053 6.94281C4.14049 6.69276 4.00001 6.35362 4.00001 6C4.00001 5.64638 4.14049 5.30724 4.39053 5.05719C4.64058 4.80714 4.97972 4.66667 5.33334 4.66667C5.68697 4.66667 6.0261 4.80714 6.27615 5.05719C6.5262 5.30724 6.66668 5.64638 6.66668 6C6.66668 6.35362 6.5262 6.69276 6.27615 6.94281C6.0261 7.19286 5.68697 7.33333 5.33334 7.33333Z"
      />
    </Svg>
  );
};
