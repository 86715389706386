import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const FileCopyLine = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 16 16">
      <Path fill="none" d="M0 0h16v16H0z" />
      <Path
        fill={color}
        d="M4.66667 4.00001V2.00001C4.66667 1.8232 4.7369 1.65363 4.86193 1.52861C4.98695 1.40358 5.15652 1.33334 5.33333 1.33334H13.3333C13.5101 1.33334 13.6797 1.40358 13.8047 1.52861C13.9298 1.65363 14 1.8232 14 2.00001V11.3333C14 11.5102 13.9298 11.6797 13.8047 11.8047C13.6797 11.9298 13.5101 12 13.3333 12H11.3333V14C11.3333 14.368 11.0333 14.6667 10.662 14.6667H2.67133C2.58342 14.6672 2.49626 14.6504 2.41488 14.6171C2.3335 14.5838 2.25949 14.5348 2.19711 14.4729C2.13472 14.4109 2.0852 14.3373 2.05137 14.2561C2.01754 14.175 2.00009 14.0879 2 14L2.002 4.66668C2.002 4.29868 2.302 4.00001 2.67267 4.00001H4.66667ZM3.33467 5.33334L3.33333 13.3333H10V5.33334H3.33467ZM6 4.00001H11.3333V10.6667H12.6667V2.66668H6V4.00001ZM4.66667 7.33334H8.66667V8.66668H4.66667V7.33334ZM4.66667 10H8.66667V11.3333H4.66667V10Z"
      />
    </Svg>
  );
};
