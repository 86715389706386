import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const Image2Line = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 16 16">
      <Path fill="none" d="M0 0h16v16H0z" />
      <Path
        fill={color}
        d="M3.33333 7.4L4.66667 6.06667L8.33333 9.73333L10.6667 7.4L12.6667 9.4V3.33333H3.33333V7.4ZM3.33333 9.286V12.6667H5.4L7.39067 10.6767L4.66667 7.95267L3.33333 9.286ZM7.286 12.6667H12.6667V11.286L10.6667 9.286L7.286 12.6667ZM2.66667 2H13.3333C13.5101 2 13.6797 2.07024 13.8047 2.19526C13.9298 2.32029 14 2.48986 14 2.66667V13.3333C14 13.5101 13.9298 13.6797 13.8047 13.8047C13.6797 13.9298 13.5101 14 13.3333 14H2.66667C2.48986 14 2.32029 13.9298 2.19526 13.8047C2.07024 13.6797 2 13.5101 2 13.3333V2.66667C2 2.48986 2.07024 2.32029 2.19526 2.19526C2.32029 2.07024 2.48986 2 2.66667 2ZM10.3333 6.66667C10.0681 6.66667 9.81376 6.56131 9.62623 6.37377C9.43869 6.18624 9.33333 5.93188 9.33333 5.66667C9.33333 5.40145 9.43869 5.1471 9.62623 4.95956C9.81376 4.77202 10.0681 4.66667 10.3333 4.66667C10.5985 4.66667 10.8529 4.77202 11.0404 4.95956C11.228 5.1471 11.3333 5.40145 11.3333 5.66667C11.3333 5.93188 11.228 6.18624 11.0404 6.37377C10.8529 6.56131 10.5985 6.66667 10.3333 6.66667Z"
      />
    </Svg>
  );
};
