import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const QuestionMark = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 16 16">
      <Path fill="none" d="M0 0h16v16H0z" />
      <Path
        fill={color}
        d="M8 12.6667C8.552 12.6667 9 13.1147 9 13.6667C9 14.2187 8.552 14.6667 8 14.6667C7.448 14.6667 7 14.2187 7 13.6667C7 13.1147 7.448 12.6667 8 12.6667ZM8 1.33334C10.2093 1.33334 12 3.12401 12 5.33334C12 6.77668 11.498 7.52668 10.2173 8.61534C8.93267 9.70668 8.66667 10.198 8.66667 11.3333H7.33333C7.33333 9.68401 7.858 8.87001 9.354 7.59934C10.3653 6.74001 10.6667 6.28934 10.6667 5.33334C10.6667 3.86001 9.47333 2.66668 8 2.66668C6.52667 2.66668 5.33333 3.86001 5.33333 5.33334V6.00001H4V5.33334C4 3.12401 5.79067 1.33334 8 1.33334Z"
      />
    </Svg>
  );
};
