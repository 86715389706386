import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const ArchiveLine = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 16 16">
      <Path fill="none" d="M0 0h16v16H0z" />
      <Path
        fill={color}
        d="M1.99998 6.66667H1.33331V2.66867C1.33331 2.29933 1.63665 2 1.99465 2H14.0053C14.0928 1.99982 14.1795 2.01704 14.2603 2.05067C14.3411 2.0843 14.4144 2.13366 14.476 2.19589C14.5375 2.25812 14.5861 2.33196 14.6188 2.41313C14.6515 2.49429 14.6678 2.58116 14.6666 2.66867V6.66667H14V13.334C14.0002 13.4212 13.9833 13.5076 13.9502 13.5883C13.9171 13.6689 13.8684 13.7423 13.8069 13.8041C13.7454 13.866 13.6724 13.9151 13.5919 13.9487C13.5115 13.9823 13.4252 13.9997 13.338 14H2.66198C2.57478 13.9997 2.48849 13.9823 2.40803 13.9487C2.32757 13.9151 2.25452 13.866 2.19305 13.8041C2.13157 13.7423 2.08288 13.6689 2.04976 13.5883C2.01663 13.5076 1.99972 13.4212 1.99998 13.334V6.66667ZM12.6666 6.66667H3.33331V12.6667H12.6666V6.66667ZM2.66665 3.33333V5.33333H13.3333V3.33333H2.66665ZM5.99998 8H9.99998V9.33333H5.99998V8Z"
      />
    </Svg>
  );
};
