import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const ChitChat = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 16 16">
      <Path fill="none" d="M0 0h16v16H0z" />
      <Path
        fill={color}
        d="M5.24998 11C5.24998 11.4168 5.39836 11.8285 5.68044 12.1419C5.96455 12.4576 6.36547 12.65 6.79998 12.65H11.9105L13.5168 14.4348C13.6969 14.6348 13.9816 14.7032 14.2328 14.6068C14.4841 14.5104 14.65 14.2691 14.65 14V5.99998C14.65 5.58318 14.5016 5.17146 14.2195 4.85804C13.9354 4.54237 13.5345 4.34998 13.1 4.34998H12.5V5.64998H13.1C13.1429 5.64998 13.1998 5.6683 13.2532 5.7277C13.3087 5.78935 13.35 5.88634 13.35 5.99998V12.3061L12.6831 11.5651C12.5598 11.4282 12.3842 11.35 12.2 11.35H6.79998C6.7571 11.35 6.70018 11.3317 6.64672 11.2723C6.59123 11.2106 6.54998 11.1136 6.54998 11H5.24998Z"
      />
      <Path
        fill={color}
        d="M2.99998 3.14998C2.90715 3.14998 2.81813 3.18685 2.75249 3.25249C2.68685 3.31813 2.64998 3.40715 2.64998 3.49998V9.93074L3.54036 9.04036C3.66225 8.91846 3.82758 8.84998 3.99998 8.84998H9.99998C10.0928 8.84998 10.1818 8.8131 10.2475 8.74746C10.3131 8.68182 10.35 8.5928 10.35 8.49998V3.49998C10.35 3.40715 10.3131 3.31813 10.2475 3.25249C10.1818 3.18685 10.0928 3.14998 9.99998 3.14998H2.99998ZM1.83325 2.33325C2.14268 2.02381 2.56237 1.84998 2.99998 1.84998H9.99998C10.4376 1.84998 10.8573 2.02381 11.1667 2.33325C11.4761 2.64268 11.65 3.06237 11.65 3.49998V8.49998C11.65 8.93758 11.4761 9.35727 11.1667 9.6667C10.8573 9.97614 10.4376 10.15 9.99998 10.15H4.26921L2.45959 11.9596C2.2737 12.1455 1.99412 12.2011 1.75123 12.1005C1.50834 11.9999 1.34998 11.7629 1.34998 11.5V3.49998C1.34998 3.06237 1.52381 2.64268 1.83325 2.33325Z"
      />
    </Svg>
  );
};
