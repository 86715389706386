import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const DeleteBinLine = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 16 16">
      <Path fill="none" d="M0 0h16v16H0z" />
      <Path
        fill={color}
        d="M11.3333 4.00001H14.6666V5.33334H13.3333V14C13.3333 14.1768 13.2631 14.3464 13.1381 14.4714C13.013 14.5964 12.8435 14.6667 12.6666 14.6667H3.33331C3.1565 14.6667 2.98693 14.5964 2.86191 14.4714C2.73688 14.3464 2.66665 14.1768 2.66665 14V5.33334H1.33331V4.00001H4.66665V2.00001C4.66665 1.8232 4.73688 1.65363 4.86191 1.52861C4.98693 1.40358 5.1565 1.33334 5.33331 1.33334H10.6666C10.8435 1.33334 11.013 1.40358 11.1381 1.52861C11.2631 1.65363 11.3333 1.8232 11.3333 2.00001V4.00001ZM12 5.33334H3.99998V13.3333H12V5.33334ZM5.99998 7.33334H7.33331V11.3333H5.99998V7.33334ZM8.66665 7.33334H9.99998V11.3333H8.66665V7.33334ZM5.99998 2.66668V4.00001H9.99998V2.66668H5.99998Z"
      />
    </Svg>
  );
};
