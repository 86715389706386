import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const Chat1Line = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 16 16">
      <Path fill="none" d="M0 0h16v16H0z" />
      <Path
        fill={color}
        d="M6.66665 2H9.33331C10.7478 2 12.1044 2.5619 13.1045 3.5621C14.1047 4.56229 14.6666 5.91885 14.6666 7.33333C14.6666 8.74782 14.1047 10.1044 13.1045 11.1046C12.1044 12.1048 10.7478 12.6667 9.33331 12.6667V15C5.99998 13.6667 1.33331 11.6667 1.33331 7.33333C1.33331 5.91885 1.89522 4.56229 2.89541 3.5621C3.8956 2.5619 5.25216 2 6.66665 2ZM7.99998 11.3333H9.33331C9.8586 11.3333 10.3787 11.2299 10.864 11.0289C11.3493 10.8278 11.7903 10.5332 12.1617 10.1618C12.5332 9.79033 12.8278 9.34937 13.0288 8.86407C13.2299 8.37877 13.3333 7.85862 13.3333 7.33333C13.3333 6.80805 13.2299 6.2879 13.0288 5.8026C12.8278 5.3173 12.5332 4.87634 12.1617 4.50491C11.7903 4.13347 11.3493 3.83883 10.864 3.63782C10.3787 3.4368 9.8586 3.33333 9.33331 3.33333H6.66665C5.60578 3.33333 4.58836 3.75476 3.83822 4.50491C3.08807 5.25505 2.66665 6.27247 2.66665 7.33333C2.66665 9.74 4.30798 11.3107 7.99998 12.9867V11.3333Z"
      />
    </Svg>
  );
};
