import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const AtLine = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 16 16">
      <Path fill="none" d="M0 0h16v16H0z" />
      <Path
        fill={color}
        d="M13.3333 8.00001C13.3332 6.81127 12.9359 5.65664 12.2046 4.71947C11.4733 3.78231 10.4498 3.11633 9.29676 2.8273C8.14369 2.53827 6.92711 2.64275 5.8402 3.12415C4.75329 3.60555 3.85837 4.43627 3.29753 5.4844C2.7367 6.53254 2.54212 7.738 2.74467 8.90936C2.94723 10.0807 3.53531 11.1508 4.41555 11.9498C5.29578 12.7487 6.4177 13.2307 7.60315 13.3191C8.7886 13.4076 9.96962 13.0975 10.9587 12.438L11.6987 13.5473C10.6041 14.2792 9.31665 14.6688 8.00001 14.6667C4.31801 14.6667 1.33334 11.682 1.33334 8.00001C1.33334 4.31801 4.31801 1.33334 8.00001 1.33334C11.682 1.33334 14.6667 4.31801 14.6667 8.00001V9.00001C14.6667 9.49918 14.5067 9.98521 14.2102 10.3867C13.9136 10.7882 13.496 11.084 13.0189 11.2307C12.5418 11.3774 12.0302 11.3671 11.5593 11.2015C11.0884 11.0359 10.683 10.7237 10.4027 10.3107C9.95773 10.7733 9.38865 11.0977 8.76382 11.2448C8.13899 11.3918 7.48499 11.3554 6.88039 11.1397C6.27578 10.9241 5.7463 10.5385 5.35555 10.0292C4.9648 9.51995 4.7294 8.90869 4.67764 8.26888C4.62588 7.62907 4.75995 6.98792 5.06376 6.42246C5.36757 5.857 5.82818 5.39129 6.39026 5.08128C6.95233 4.77127 7.59197 4.63014 8.23231 4.67485C8.87266 4.71957 9.48647 4.94822 10 5.33334H11.3333V9.00001C11.3333 9.26523 11.4387 9.51958 11.6262 9.70712C11.8138 9.89465 12.0681 10 12.3333 10C12.5986 10 12.8529 9.89465 13.0405 9.70712C13.228 9.51958 13.3333 9.26523 13.3333 9.00001V8.00001ZM8.00001 6.00001C7.46958 6.00001 6.96087 6.21072 6.5858 6.5858C6.21072 6.96087 6.00001 7.46958 6.00001 8.00001C6.00001 8.53044 6.21072 9.03915 6.5858 9.41422C6.96087 9.7893 7.46958 10 8.00001 10C8.53044 10 9.03915 9.7893 9.41422 9.41422C9.7893 9.03915 10 8.53044 10 8.00001C10 7.46958 9.7893 6.96087 9.41422 6.5858C9.03915 6.21072 8.53044 6.00001 8.00001 6.00001Z"
      />
    </Svg>
  );
};
