import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const ArrowGoBackLine = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 16 16">
      <Path fill="none" d="M0 0h16v16H0z" />
      <Path
        fill={color}
        d="M3.88533 4.66668L5.576 6.35735L4.63333 7.30001L1.33333 4.00001L4.63333 0.700012L5.576 1.64268L3.88533 3.33335H8.66666C10.0812 3.33335 11.4377 3.89525 12.4379 4.89544C13.4381 5.89564 14 7.25219 14 8.66668C14 10.0812 13.4381 11.4377 12.4379 12.4379C11.4377 13.4381 10.0812 14 8.66666 14H2.66666V12.6667H8.66666C9.72753 12.6667 10.7449 12.2453 11.4951 11.4951C12.2452 10.745 12.6667 9.72754 12.6667 8.66668C12.6667 7.60581 12.2452 6.5884 11.4951 5.83825C10.7449 5.08811 9.72753 4.66668 8.66666 4.66668H3.88533Z"
      />
    </Svg>
  );
};
