import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const H2 = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 16 16">
      <Path fill="none" d="M0 0h16v16H0z" />
      <Path
        fill={color}
        d="M2.66666 2.66666V7.33332H7.33333V2.66666H8.66666V13.3333H7.33333V8.66666H2.66666V13.3333H1.33333V2.66666H2.66666ZM12.3333 5.33332C13.714 5.33332 14.8333 6.45266 14.8333 7.83332C14.8333 8.40466 14.6413 8.93199 14.3187 9.35332L14.22 9.47332L12.0227 12H14.6667V13.3333H10V12.296L13.2133 8.59866C13.392 8.39399 13.5 8.12599 13.5 7.83332C13.5 7.18932 12.9773 6.66666 12.3333 6.66666C11.7213 6.66666 11.2193 7.13799 11.1707 7.73732L11.1667 7.83332H9.83333C9.83333 6.45266 10.9527 5.33332 12.3333 5.33332Z"
      />
    </Svg>
  );
};
