import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const LinkM = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 16 16">
      <Path fill="none" d="M0 0h16v16H0z" />
      <Path
        fill={color}
        d="M11.7713 9.88533L10.8287 8.94266L11.7713 7.99999C12.019 7.75236 12.2154 7.45838 12.3494 7.13484C12.4834 6.8113 12.5524 6.46453 12.5524 6.11433C12.5524 5.76413 12.4834 5.41735 12.3494 5.09381C12.2154 4.77027 12.019 4.47629 11.7713 4.22866C11.5237 3.98103 11.2297 3.7846 10.9062 3.65058C10.5826 3.51657 10.2359 3.44759 9.88566 3.44759C9.53546 3.44759 9.18869 3.51657 8.86514 3.65058C8.5416 3.7846 8.24762 3.98103 7.99999 4.22866L7.05733 5.17133L6.11466 4.22866L7.05733 3.28599C7.80953 2.54594 8.8237 2.1331 9.87891 2.13739C10.9341 2.14169 11.9449 2.56278 12.6911 3.30893C13.4372 4.05509 13.8583 5.06586 13.8626 6.12107C13.8669 7.17629 13.454 8.19045 12.714 8.94266L11.7713 9.88533ZM9.88533 11.7713L8.94266 12.714C8.57234 13.0904 8.13116 13.3897 7.64457 13.5948C7.15798 13.7998 6.63561 13.9065 6.10758 13.9087C5.57956 13.9108 5.05633 13.8084 4.56809 13.6073C4.07984 13.4062 3.63624 13.1105 3.26287 12.7371C2.88949 12.3637 2.59374 11.9201 2.39267 11.4319C2.19159 10.9437 2.08918 10.4204 2.09133 9.89241C2.09348 9.36438 2.20015 8.84201 2.40519 8.35542C2.61024 7.86882 2.90959 7.42765 3.28599 7.05733L4.22866 6.11466L5.17133 7.05733L4.22866 7.99999C3.98103 8.24762 3.7846 8.5416 3.65059 8.86514C3.51657 9.18869 3.44759 9.53546 3.44759 9.88566C3.44759 10.2359 3.51657 10.5826 3.65059 10.9062C3.7846 11.2297 3.98103 11.5237 4.22866 11.7713C4.47629 12.019 4.77027 12.2154 5.09381 12.3494C5.41735 12.4834 5.76413 12.5524 6.11433 12.5524C6.46453 12.5524 6.8113 12.4834 7.13484 12.3494C7.45839 12.2154 7.75236 12.019 7.99999 11.7713L8.94266 10.8287L9.88533 11.7713ZM9.88533 5.17133L10.8287 6.11466L6.11466 10.828L5.17133 9.88533L9.88533 5.17199V5.17133Z"
      />
    </Svg>
  );
};
