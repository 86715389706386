import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const Strikethrough = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 16 16">
      <Path fill="none" d="M0 0h16v16H0z" />
      <Path
        fill={color}
        d="M11.436 9.33332C11.5893 9.67732 11.6667 10.06 11.6667 10.48C11.6667 11.3747 11.3173 12.0747 10.6193 12.578C9.92 13.0813 8.95533 13.3333 7.724 13.3333C6.63067 13.3333 5.54867 13.0793 4.47733 12.5707V11.0667C5.49067 11.6513 6.52733 11.944 7.588 11.944C9.28867 11.944 10.1413 11.456 10.1473 10.4793C10.1509 10.2813 10.1144 10.0846 10.0402 9.90099C9.96596 9.71737 9.85547 9.55061 9.71533 9.41066L9.63533 9.33266H2V7.99932H14V9.33266H11.436V9.33332ZM8.71733 7.33332H5.086C4.96922 7.22684 4.86192 7.11041 4.76533 6.98532C4.47733 6.61332 4.33333 6.16399 4.33333 5.63466C4.33333 4.81066 4.644 4.10999 5.26467 3.53266C5.88667 2.95532 6.84733 2.66666 8.148 2.66666C9.12867 2.66666 10.0673 2.88532 10.9627 3.32266V4.75732C10.1627 4.29932 9.286 4.07066 8.332 4.07066C6.67867 4.07066 5.85267 4.59199 5.85267 5.63466C5.85267 5.91466 5.998 6.15866 6.28867 6.36732C6.57933 6.57599 6.938 6.74199 7.364 6.86732C7.77733 6.98732 8.22867 7.14332 8.71733 7.33332Z"
      />
    </Svg>
  );
};
