import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const H3 = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 16 16">
      <Path fill="none" d="M0 0h16v16H0z" />
      <Path
        fill={color}
        d="M14.6667 5.33332L14.6653 6.66666L12.9953 8.58866C14.0553 8.87866 14.8333 9.84866 14.8333 11C14.8333 12.3807 13.714 13.5 12.3333 13.5C11.116 13.5 10.102 12.63 9.87868 11.478L11.188 11.2233C11.292 11.7607 11.7653 12.1667 12.3333 12.1667C12.9773 12.1667 13.5 11.644 13.5 11C13.5 10.356 12.9773 9.83332 12.3333 9.83332C12.1427 9.83332 11.9627 9.87932 11.804 9.95999L10.9327 8.92866L12.9 6.66666H10V5.33332H14.6667ZM2.66668 2.66666V7.33332H7.33334V2.66666H8.66668V13.3333H7.33334V8.66666H2.66668V13.3333H1.33334V2.66666H2.66668Z"
      />
    </Svg>
  );
};
