import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const EmotionLine = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 16 16">
      <Path fill="none" d="M0 0h16v16H0z" />
      <Path
        fill={color}
        d="M8 14.6667C4.318 14.6667 1.33333 11.682 1.33333 8C1.33333 4.318 4.318 1.33333 8 1.33333C11.682 1.33333 14.6667 4.318 14.6667 8C14.6667 11.682 11.682 14.6667 8 14.6667ZM8 13.3333C9.41448 13.3333 10.771 12.7714 11.7712 11.7712C12.7714 10.771 13.3333 9.41448 13.3333 8C13.3333 6.58551 12.7714 5.22895 11.7712 4.22876C10.771 3.22856 9.41448 2.66666 8 2.66666C6.58551 2.66666 5.22895 3.22856 4.22876 4.22876C3.22856 5.22895 2.66666 6.58551 2.66666 8C2.66666 9.41448 3.22856 10.771 4.22876 11.7712C5.22895 12.7714 6.58551 13.3333 8 13.3333ZM5.33333 8.66666H10.6667C10.6667 9.37391 10.3857 10.0522 9.88561 10.5523C9.38552 11.0524 8.70724 11.3333 8 11.3333C7.29275 11.3333 6.61447 11.0524 6.11438 10.5523C5.61428 10.0522 5.33333 9.37391 5.33333 8.66666ZM5.33333 7.33333C5.06811 7.33333 4.81376 7.22797 4.62622 7.04044C4.43868 6.8529 4.33333 6.59855 4.33333 6.33333C4.33333 6.06811 4.43868 5.81376 4.62622 5.62622C4.81376 5.43868 5.06811 5.33333 5.33333 5.33333C5.59855 5.33333 5.8529 5.43868 6.04044 5.62622C6.22797 5.81376 6.33333 6.06811 6.33333 6.33333C6.33333 6.59855 6.22797 6.8529 6.04044 7.04044C5.8529 7.22797 5.59855 7.33333 5.33333 7.33333ZM10.6667 7.33333C10.4014 7.33333 10.1471 7.22797 9.95955 7.04044C9.77202 6.8529 9.66666 6.59855 9.66666 6.33333C9.66666 6.06811 9.77202 5.81376 9.95955 5.62622C10.1471 5.43868 10.4014 5.33333 10.6667 5.33333C10.9319 5.33333 11.1862 5.43868 11.3738 5.62622C11.5613 5.81376 11.6667 6.06811 11.6667 6.33333C11.6667 6.59855 11.5613 6.8529 11.3738 7.04044C11.1862 7.22797 10.9319 7.33333 10.6667 7.33333Z"
      />
    </Svg>
  );
};
