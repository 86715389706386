import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const SearchLine = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 16 16">
      <Path fill="none" d="M0 0h16v16H0z" />
      <Path
        fill={color}
        d="M12.0206 11.078L14.876 13.9327L13.9326 14.876L11.078 12.0207C10.0158 12.8722 8.69465 13.3353 7.33331 13.3333C4.02131 13.3333 1.33331 10.6453 1.33331 7.33334C1.33331 4.02134 4.02131 1.33334 7.33331 1.33334C10.6453 1.33334 13.3333 4.02134 13.3333 7.33334C13.3353 8.69468 12.8721 10.0158 12.0206 11.078ZM10.6833 10.5833C11.5294 9.71327 12.0019 8.54696 12 7.33334C12 4.75468 9.91131 2.66668 7.33331 2.66668C4.75465 2.66668 2.66665 4.75468 2.66665 7.33334C2.66665 9.91134 4.75465 12 7.33331 12C8.54693 12.0019 9.71324 11.5294 10.5833 10.6833L10.6833 10.5833Z"
      />
    </Svg>
  );
};
