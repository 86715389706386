import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const H6 = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 16 16">
      <Path fill="none" d="M0 0h16v16H0z" />
      <Path
        fill={color}
        d="M14.0647 5.33332L12.3327 8.33332C13.806 8.33332 15 9.52666 15 11C15 12.4733 13.8067 13.6667 12.3333 13.6667C10.86 13.6667 9.66666 12.4733 9.66666 11C9.66666 10.5093 9.79933 10.0493 10.0307 9.65399L12.5253 5.33332H14.0647ZM2.66666 2.66666V7.33332H7.33333V2.66666H8.66666V13.3333H7.33333V8.66666H2.66666V13.3333H1.33333V2.66666H2.66666ZM12.3333 9.66666C11.5967 9.66666 11 10.2633 11 11C11 11.7367 11.5967 12.3333 12.3333 12.3333C13.07 12.3333 13.6667 11.7367 13.6667 11C13.6667 10.2633 13.07 9.66666 12.3333 9.66666Z"
      />
    </Svg>
  );
};
