import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const H5 = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 16 16">
      <Path fill="none" d="M0 0h16v16H0z" />
      <Path
        fill={color}
        d="M14.6667 5.33332V6.66666H11.7847L11.4753 8.42399C11.6953 8.36466 11.9273 8.33332 12.1667 8.33332C13.64 8.33332 14.8333 9.52666 14.8333 11C14.8333 12.4733 13.64 13.6667 12.1667 13.6667C10.9487 13.6667 9.92201 12.8507 9.60268 11.7353L10.8847 11.368C11.0447 11.9253 11.558 12.3333 12.1667 12.3333C12.9033 12.3333 13.5 11.7367 13.5 11C13.5 10.2633 12.9033 9.66666 12.1667 9.66666C11.7467 9.66666 11.3713 9.86132 11.1267 10.1653L9.92001 9.56266L10.6667 5.33332H14.6667ZM2.66668 2.66666V7.33332H7.33334V2.66666H8.66668V13.3333H7.33334V8.66666H2.66668V13.3333H1.33334V2.66666H2.66668Z"
      />
    </Svg>
  );
};
