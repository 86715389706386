import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const CalendarCheckFill = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 16 16">
      <Path fill="none" d="M0 0h16v16H0z" />
      <Path
        fill={color}
        d="M6.00001 0.666656V1.99999H10V0.666656H11.3333V1.99999H14C14.1768 1.99999 14.3464 2.07023 14.4714 2.19525C14.5964 2.32028 14.6667 2.48985 14.6667 2.66666V13.3333C14.6667 13.5101 14.5964 13.6797 14.4714 13.8047C14.3464 13.9298 14.1768 14 14 14H2.00001C1.8232 14 1.65363 13.9298 1.52861 13.8047C1.40358 13.6797 1.33334 13.5101 1.33334 13.3333V2.66666C1.33334 2.48985 1.40358 2.32028 1.52861 2.19525C1.65363 2.07023 1.8232 1.99999 2.00001 1.99999H4.66668V0.666656H6.00001ZM13.3333 5.33332H2.66668V12.6667H13.3333V5.33332ZM10.024 6.75732L10.9667 7.69999L7.66668 11L5.30934 8.64266L6.25334 7.69999L7.66734 9.11466L10.0247 6.75732H10.024Z"
      />
    </Svg>
  );
};
