import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const FolderMusicLine = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 16 16">
      <Path fill="none" d="M0 0h16v16H0z" />
      <Path
        fill={color}
        d="M8.27601 3.33333H14C14.1768 3.33333 14.3464 3.40357 14.4714 3.5286C14.5964 3.65362 14.6667 3.82319 14.6667 4V13.3333C14.6667 13.5101 14.5964 13.6797 14.4714 13.8047C14.3464 13.9298 14.1768 14 14 14H2.00001C1.8232 14 1.65363 13.9298 1.52861 13.8047C1.40358 13.6797 1.33334 13.5101 1.33334 13.3333V2.66667C1.33334 2.48986 1.40358 2.32029 1.52861 2.19526C1.65363 2.07024 1.8232 2 2.00001 2H6.94268L8.27601 3.33333ZM2.66668 3.33333V12.6667H13.3333V4.66667H7.72401L6.39068 3.33333H2.66668ZM7.33334 8.7V6H10.6667V7.33333H8.66668V10.3333C8.66661 10.6773 8.56014 11.0127 8.36188 11.2938C8.16362 11.5748 7.88327 11.7876 7.55929 11.903C7.2353 12.0184 6.88355 12.0308 6.55227 11.9384C6.22099 11.846 5.9264 11.6534 5.70891 11.387C5.49142 11.1205 5.36168 10.7933 5.33747 10.4503C5.31327 10.1072 5.3958 9.76504 5.57374 9.47072C5.75167 9.1764 6.0163 8.94433 6.33133 8.80635C6.64636 8.66836 6.99637 8.63122 7.33334 8.7Z"
      />
    </Svg>
  );
};
