import React from "react";
import Svg, { Path } from "react-native-svg";

export type Props = { color: string; size: string };

export const CupLine = ({ color, size }: Props) => {
  return (
    <Svg height={size} width={size} viewBox="0 0 16 16">
      <Path fill="none" d="M0 0h16v16H0z" />
      <Path
        fill={color}
        d="M10.6666 11.4667V6.13335H3.99996V11.4667C3.99996 11.8203 4.14044 12.1594 4.39048 12.4095C4.64053 12.6595 4.97967 12.8 5.33329 12.8H9.33329C9.68691 12.8 10.0261 12.6595 10.2761 12.4095C10.5261 12.1594 10.6666 11.8203 10.6666 11.4667ZM3.33329 4.80002H13.3333C13.6869 4.80002 14.0261 4.94049 14.2761 5.19054C14.5262 5.44059 14.6666 5.77973 14.6666 6.13335V8.13335C14.6666 8.48697 14.5262 8.82611 14.2761 9.07616C14.0261 9.32621 13.6869 9.46668 13.3333 9.46668H12V11.4667C12 12.1739 11.719 12.8522 11.2189 13.3523C10.7188 13.8524 10.0405 14.1334 9.33329 14.1334H5.33329C4.62605 14.1334 3.94777 13.8524 3.44767 13.3523C2.94758 12.8522 2.66663 12.1739 2.66663 11.4667V5.46668C2.66663 5.28987 2.73686 5.1203 2.86189 4.99528C2.98691 4.87026 3.15648 4.80002 3.33329 4.80002ZM12 6.13335V8.13335H13.3333V6.13335H12Z"
      />
      <Path
        stroke={color}
        strokeWidth="1.5"
        d="M4.80005 1.60001V4.00001M7.20005 1.60001V4.00001M9.60005 1.60001V4.00001"
      />
    </Svg>
  );
};
